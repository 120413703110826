import { createApp, defineAsyncComponent } from 'vue';

import './assets/styles/main.scss';

import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/collapse';

import NalLogo from './components/framework/logo/NalLogo.vue';
import RedaktoransvarLogo from './components/framework/logo/RedaktoransvarLogo.vue';
import HeaderComponent from './components/framework/header/Header.vue';
import MenuComponent from './components/framework/navigation/Menu.vue';
import CategoryMenu from './components/framework/navigation/MenuCategory.vue';
import FactsListBlock from './components/blocks/facts-list-block/FactsListBlock.vue';
import AdBlock from './components/blocks/ad-block/AdBlock.vue';
import AdFullbleedBlock from './components/blocks/ad-block/AdFullbleedBlock.vue';
import LoginComponent from './components/framework/login/Login.vue';
import RelatedProject from './components/framework/project-list/RelatedProject.vue';
import HCaptcha from './components/framework/hcaptcha/HCaptcha.vue';

const newsletter = defineAsyncComponent(() =>
    import('./assets/styles/newsletter.scss')
);

const ImagesBlockHorizontal = defineAsyncComponent(() =>
    import('./components/blocks/images-block/ImagesBlockHorizontal.vue')
);

const VideoBlock = defineAsyncComponent(() =>
    import('./components/blocks/video-block/VideoBlock.vue')
);

const ProjectList = defineAsyncComponent(() =>
    import('./components/framework/project-list/ProjectList.vue')
);

const MapSingle = defineAsyncComponent(() =>
    import('./components/framework/project-list/MapSingle.vue')
);

const app = createApp();

// Add global components
app.component('nal-logo', NalLogo);
app.component('redaktoransvar-logo', RedaktoransvarLogo);
app.component('header-component', HeaderComponent);
app.component('menu-component', MenuComponent);
app.component('menu-category-component', CategoryMenu);
app.component('facts-list-block', FactsListBlock);
app.component('ad-block-component', AdBlock);
app.component('ad-fullbleed-block-component', AdFullbleedBlock);
app.component('login-component', LoginComponent);
app.component('related-project', RelatedProject);
app.component('hcaptcha', HCaptcha);

app.component('images-block-horizontal', ImagesBlockHorizontal);
app.component('video-block-component', VideoBlock);
app.component('project-list-component', ProjectList);
app.component('map-single-component', MapSingle);

import { createPinia } from 'pinia';
app.use(createPinia());


app.mount('#app');

loadAds();
loadInfinite();
loadCitations();

async function loadAds() {
    let module = await import('./lib/config/adnuntius');
    module.RequestAds();
}

async function loadInfinite() {
    await import('./lib/config/infinite');
}

async function loadCitations() {
    const module = await import('./citationHandler');
    window.addEventListener("load", module.setCitationText, false);
}