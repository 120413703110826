<script setup>
    import { useGlobalStore } from '@/lib/stores/global-store';
    import Logo from '../Logo/Logo.vue';
    import { onMounted, ref } from 'vue';
    import {min} from "@popperjs/core/lib/utils/math";

    const store = useGlobalStore();

    const props = defineProps({
        primaryLinks: Array,
        searchLink: String
    });

    const isFrontpage = ref(false);

    const onMenuButtonClick = () => {
        store.toggleMenu(true);
    };

    let lastWidth = 0;
    let lastHeight = 0;
    let fullLogoWidth = 0;
    let gutterXValue = 0;

    onMounted(() => {
      let lastScrollTop;
      let header = document.getElementsByClassName('header')[0];
      let darkMode = document.body.classList.contains('theme--dark');
      let landing = document.body.classList.contains('page--landing');
      let opinionList = document.body.classList.contains('page--opinionlanding');
      let opinion = document.body.classList.contains('page--opinion');
      let projectLandingPage = document.body.classList.contains('page--projectlanding');
      let tema = document.body.classList.contains('theme');
      let alternative = document.body.classList.contains('alternative');
      let portrait = document.body.classList.contains('portrait');

      if (darkMode) {
        header.classList.add('theme--dark');
      }
      if (tema && alternative) {
        header.classList.add('theme-alternative');
      }
      if (landing) {
        header.classList.add('theme--frontpage');
        isFrontpage.value = true;
      }
      if (opinion || opinionList) {
        header.classList.add('theme--opinion');
      }
      if (projectLandingPage) {
        header.classList.add('theme--project-landing-page');
      }
      if (portrait) {
        header.classList.add('theme--portrait');
        var menuItems = document.getElementsByClassName('primary-menu');
        [].forEach.call(menuItems, function (el) {
          el.classList.add('d-none');
        });
      }

      const maxScroll = 45; // Adjust for how far scrolling affects the scaling
      const headerInner = document.querySelector(".header__inner");

      if (isFrontpage.value && window.scrollY < maxScroll) {
        header.classList.add('navbar--logo-visible');
        const headerInnerContainer = headerInner.parentElement
        const gutterX = getComputedStyle(headerInnerContainer).getPropertyValue('--bs-gutter-x');
        gutterXValue = parseFloat(gutterX); // used to accurately calculate "big logo" width, even when it is being actively scaled down
      }

      window.addEventListener('scroll', function () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        // Scrolling down
        if (scrollTop > lastScrollTop) {
          header.classList.add('navbar--scroll-down');
          header.classList.remove('navbar--scroll-up');
          header.classList.remove('navbar--scroll-top');
        }
        // Scrolling up
        else {
          header.classList.remove('navbar--scroll-down');
          header.classList.add('navbar--scroll-up');
          header.classList.remove('navbar--scroll-top');
        }
        // Scrolling all the way up
        if (document.documentElement.scrollTop == 0) {
          header.classList.remove('navbar--scroll-down');
          header.classList.remove('navbar--scroll-up');
          header.classList.add('navbar--scroll-top');
        }
        lastScrollTop = scrollTop;

        const headerLogo = document.querySelector(".header__logo.big");

        const targetWidth = 210; // Header minimum width in pixels (makes sure logo stops shrinking when it has the correct size)

        // Calculate scale dynamically, clamped between the minimum and maximum scale
        const fullLogoWidth = header.offsetWidth - gutterXValue;
        const minScale = targetWidth >= fullLogoWidth ? 1 : targetWidth / fullLogoWidth; // Ensure 420px width at minimum
        const scrollTopPos = window.scrollY;
        const scale = Math.max(minScale, 1 - scrollTopPos / (maxScroll / minScale));

        if (scale > minScale) { // header scale animation still visible
          headerLogo.style.transform = `scale(${scale})`; //we have to use scale, since height messes with scrollbar position
          header.classList.add('navbar--logo-visible');
          header.style.top = `` // remove offset from scroll up
          headerInner.style.top = `` // remove offset from scroll up
        } else {
          headerLogo.style.transform = `scale(1)`;
          header.classList.remove('navbar--logo-visible');
          if (header.classList.contains('navbar--scroll-up')) {
            header.style.top = `calc(69px - ${header.offsetWidth * 0.28}px)`
            headerInner.style.top = `calc(-69px + ${header.offsetWidth * 0.28}px)`
          } else {
            header.style.top = ``
            headerInner.style.top = ``
          }
        }
      });
    });
</script>

<template>
    <header class="header">
        <div class="container">
            <div class="header__inner">
                <a class="header__logo big" href="/" v-if="isFrontpage">
                    <Logo />
                    <span class="sr-only">Til startsiden</span>
                </a>
                <a class="header__logo" href="/" v-else>
                    <Logo />
                    <span class="sr-only">Til startsiden</span>
                </a>
                <nav class="header__nav d-flex justify-content-between">
                    <h2 class="sr-only">Navigasjon</h2>
                    <ul class="header__menu me-5">
                        <li
                            v-for="link in props.primaryLinks"
                            class="header__menu-item primary-menu"
                            :key="link.title"
                        >
                            <a :class="{ active: link.isActive, focusable: true}" :href="link.href">
                                {{ link.title }}
                            </a>
                        </li>
                    </ul>
                    <div class="d-flex">
                        <div class="header__menu-item header__menu-item--button align-self-center">
                            <a :href="searchLink">
                                <button tabindex="-1">
                                    <span class="icon icon--search"></span>
                                    <span class="sr-only">Søk</span>
                                </button>
                            </a>
                        </div>
                        <div class="header__menu-item header__menu-item--button align-self-center hamburger">
                            <a href="/menu" @click.prevent="onMenuButtonClick">
                                <button tabindex="-1">
                                    <span class="icon icon--menu"></span>
                                    <span class="icon icon--menu-hover"></span>
                                    <span class="sr-only">Meny</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>  
